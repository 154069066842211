import './Career.css';

import c2024 from "../../webp/tipsD.webp";
import c2023 from "../../webp/tourNewD.webp";
import c2022 from "../../webp/sponsD.webp";
import CareerStats from './CareerStats';
import React, { useState, useEffect } from "react";
import axios from "axios";
import loading from '../../videos/loadingVimeo.gif';

const Career = (props) =>{

    const [dataCareer, setData] = useState(null);
    const [career2022, set2022] = useState(null);
    const [career2023, set2023] = useState(null);
    const [career2024, set2024] = useState(null);
    const [loadingCareer, setLoading] = useState(true);
    const [errorCareer, setError] = useState(null);


    useEffect(() => {
        var url = "/Members/get_career.php";
        axios.post(url)
            .then(function (response) {
                var callie_ids = response.data;
                var callie = new Array();
                var first = new Array();
                var second = new Array();
                var third = new Array();
                callie_ids.forEach(clinic => {
                    callie.push(
                        {
                            id: clinic.id,
                            event: clinic.c_event,
                            mixed: clinic.mixed,
                            doubles: clinic.doubles,
                            singles: clinic.singles,
                        }                    
                    )
                    if(clinic.event_year == "2024"){
                        third.push({
                            id: clinic.id,
                            event: clinic.c_event,
                            mixed: clinic.mixed,
                            doubles: clinic.doubles,
                            singles: clinic.singles,
                        })
                    }
                    if(clinic.event_year == "2023"){
                        second.push({
                            id: clinic.id,
                            event: clinic.c_event,
                            mixed: clinic.mixed,
                            doubles: clinic.doubles,
                            singles: clinic.singles,
                        })
                    }
                    if(clinic.event_year == "2022"){
                        first.push({
                            id: clinic.id,
                            event: clinic.c_event,
                            mixed: clinic.mixed,
                            doubles: clinic.doubles,
                            singles: clinic.singles,
                        })
                    }
                });
                setData(callie);
                set2022(first);
                set2023(second);
                set2024(third);
            }).catch((err) => {
                setError(err.message);
                setData(null);
            }).finally(() => {
                setLoading(false);
            });
    }, []);
    
    return(
        <>
        <div className="gradient-bar"></div>
        <div className='page-Headers'><h1>CALLIE'S CAREER</h1></div>
        <div className='career_one' data-aos="fade-up" data-aos-duration={750}>
            <div className='career-box'>
                <div>
                    <h1 
                    data-aos="fade-left" 
                    data-aos-duration={1000}
                    data-aos-delay={200}
                    className='career-h1'
                    >
                        PPA Titles: 11
                    </h1>
                </div>
                <div>
                    <h1 data-aos="fade-left" 
                    data-aos-duration={1000}
                    data-aos-delay={400}
                    className='career-h1'
                    >
                        PPA Medals: 72
                    </h1>
                </div>
            </div>
        </div>
        {loadingCareer && <div style={{textAlign: 'center'}}>
                        <img src={loading} />
                    </div>}
        {dataCareer === null || (Array.isArray(dataCareer) && dataCareer.length === 0) ? (
                        <h2>Performance will be updated soon!</h2>
                    ) : (
                        Array.isArray(dataCareer) && 
                <><br /><br />
                <div className='careerGrid'>
                    <div className='backImage'>
                        <img src={c2024} className='sideImages'/>
                    </div>
                
                    <div className='Cevents-right'>
                        <h2>2024</h2>
                        {career2024.map((event, index) => (
                            <CareerStats key={index} data={event} uniquekey={index} side={'left'}/>
                        ))}
                    </div>   

                    
                    <div className='Cevents-left'>
                        <h2>2023</h2>
                        {career2023.map((event, index) => (
                            <CareerStats key={index} data={event} uniquekey={index} side={'right'}/>
                        ))}
                    </div>   
                    <div className='backImage'>
                        <img src={c2023} className='sideImages'/>
                    </div>  

                    <div className='backImage'>
                        <img src={c2022} className='sideImages'/>
                    </div>
                    <div className='Cevents-right'>
                        <h2>2022</h2>
                        {career2022.map((event, index) => (
                            <CareerStats key={index} data={event} uniquekey={index} side={'left'}/>
                        ))}
                    </div>         
                </div>
                </>
                    )}
        
        <br /><br /><br />
        </>
    )

   
}

const careerstats = [
    {
       event: "PPA SLC",
       mixed:  "SF",
       doubles: "QF",
       singles: ""
    }, 
    {
        event: "PPA Orange County",
        mixed:  "Silver",
        doubles: "Gold",
        singles: ""
     }, 
     {
        event: "PPA Florida",
        mixed:  "Bronze",
        doubles: "Bronze",
        singles: ""
     }, 
     {
        event: "PPA Texas",
        mixed:  "SF",
        doubles: "Silver",
        singles: "Bronze"
     }, 
];

export default Career;