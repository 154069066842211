import './AloeMd.css';


const TheKitchen = (props) => {
    return(
        <div style={{ display: props.display }}>
            <div className='top-header'>
                <h1>THE KITCHEN</h1>
            </div>
            <div className='kitchen-bg'>
                <div className='onix-box'>
                    <h2>AloeMD provides 100% ALL NATURAL products that help relieve all types of conditions, injuries, and pain with ZERO side effects!</h2>
                    <div className='onix-button'>
                    {/*<a href="https://www.aloemd.com/?ref=0NOcKe1Psbc64-" target="_blank" ><button className='clinic_button'>Shop AloeMD Products</button></a>*/}
                    <a href="https://linktr.ee/Calliejosmith" target="_blank"><button className='clinic_button'>Use Callie's Discount</button></a>
                    </div>                    
                </div>                         
            </div> 
            <br />      
        </div>
    )
}

export default TheKitchen;