import './TourCard.css';
import cup from '../../images/PPA Cup.png';
import open from '../../images/PPA Open.png';
import slam from '../../images/PPA Slam.png';
import mlp from '../../images/MLP Main.jpg';
import logo2 from '../../images/callie-logo.webp';



const TourCard = ({uniquekey, data}) => {
    /*var keys = uniquekey;
    if(keys == 0){
        keys = .5;
    }
    var duration = 500 * keys;
    console.log(duration);*/
    return (
        <div 
        className='tour-card'
        data-aos="fade-down"
		data-aos-duration={1000}>
            <div>
                {data.type == 'Cup' ? <img src={cup} className='scheduleImage'/> 
                : data.type == 'Open' ? <img src={open} className='scheduleImage'/>
                : data.type == 'Slam' ? <img src={slam} className='scheduleImage'/>
                : data.type == 'MLP' ? <img src={mlp} className='scheduleImage'/> 
                : <img src={logo2} className='scheduleImage'/>}
            </div>
            {data.double == "Utah Black Diamonds" ?
            (<div>
            <h2>{data.name}</h2>
            <p>{data.date}</p>
            <p>{data.location}</p>
            <p>Mixed Partner: {data.mixed}</p>
            <p>Women's Partner: {data.double}</p>
            </div>
            ) :
            (
            <div>
            <h2>{data.name}</h2>
            <p>{data.date}</p>
            <p>{data.location}</p>
            <p>MLP Team: {data.mixed}</p>
            </div> 
            )
            }

        </div>
    )
}

export default TourCard;