import './TourSchedule.css';
import React, { useState, useEffect } from "react";
import axios from "axios";
import TourCard from './TourCard';
import loading from '../../videos/loadingVimeo.gif';


const TourSchedule = (props) => {

    const [dataSchedule, setData] = useState(null);
    const [loadingSchedule, setLoading] = useState(true);
    const [errorSchedule, setError] = useState(null);


    useEffect(() => {
        var url = "/Members/get_schedule.php";
        axios.post(url)
            .then(function (response) {
                var callie_ids = response.data;
                var callie = new Array();
                callie_ids.forEach(clinic => {
                    const date = new Date(clinic.event_date);
                    const options = { month: "short", day: "numeric", year: "numeric" };
                    const formattedDate = date.toLocaleDateString("en-US", options);
                    callie.push(
                        {
                            id: clinic.event_id,
                            mixed: clinic.mixed_partner,
                            double: clinic.double_partner,
                            type: clinic.event_img,
                            location: clinic.event_state,
                            date: formattedDate,
                            name: clinic.event_name
                        }
                    )
                });
                setData(callie);
            }).catch((err) => {
                setError(err.message);
                setData(null);
            }).finally(() => {
                setLoading(false);
            });
    }, []);

    return(
        <>
        {loadingSchedule && <div style={{textAlign: 'center'}}>
                <img src={loading} />
            </div>}
        {dataSchedule === null || (Array.isArray(dataSchedule) && dataSchedule.length === 0) ? (
            <h2>Schedule will be updated soon!</h2>
        ) : (
            Array.isArray(dataSchedule) && 
            <>
            <div className="gradient-bar"></div>
            <div className='page-Headers'><h1>CALLIE'S SCHEDULE</h1></div>
            <br />
            <div className='scheduleGrid'>
                    {dataSchedule.map((event, index) => (
                        <TourCard key={index} data={event} uniquekey={index}/>
                    ))} 
                </div>: 

            </>
            )}    
                
            
        </>
    )

}

export default TourSchedule;