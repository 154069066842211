import './CareerStats.css';

const CareerStats = ({uniquekey, data, side}) => {

     var keys = uniquekey;
    if(keys == 0){
        keys = .15;
    }
    var duration = 200 * keys;
    return(
        <div 
        className='eventCard'  
        data-aos={side == 'left' ? 'fade-left' : 'fade-right'}
		data-aos-duration={1000}
        data-aos-delay={duration}
        >
            <h2>{data.event}</h2>
            <p>{data.mixed} Mixed Doubles</p>
            <p>{data.doubles} Women's Doubles</p>
            {data.singles == 'DNP' ? '' : <p>{data.singles} Singles</p>}

        </div>
    )
}

export default CareerStats;